.modal {
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    z-index: 10;
}

.alert {
    background-color: #fff;
    border-radius: 0.75rem;
    width: fit-content;
    height: fit-content;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid #EDEDED;

    @include breakpoint($sm) {
        min-width: 25rem;
    }

    .alert-header {
        padding: 20px;
        font-weight: bold;
        font-family: "Josefin Sans", sans-serif;
        font-size: 1.25rem;
        border-bottom: 1px solid #EDEDED;
        max-width: 500px;
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(stretch);
    }

    .alert-message {
        padding: 20px;
    }

    .alert-actions {
        @include flexbox;
        @include flex-direction(row);
        border-top: 1px solid #EDEDED;
    }

    .alert-action {
        @include flexbox;
        @include flex(1);
        @include justify-content(center);
        font-size: 1rem;
        width: 100%;
        padding: 1.25rem;
        background-color: #fff;
        border: none;
        color: #00A3FF;
        cursor: pointer;
        font-weight: bold;

        &:first-of-type {
            border-bottom-left-radius: 12px;
        }

        &:last-of-type {
            border-bottom-right-radius: 12px;
        }

        &.default {
            color: #00A3FF;
        }

        &.destructive {
            color: #E52828;
        }
    }
}