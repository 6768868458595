.audio-player {
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
    .footer {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);
        padding: 16px 16px 0 0;
    }

    .title {
        font-weight: bold;
        font-size: 1.25rem;
    }

    .controls {
        padding: 0 16px;
    }

    .image {
        position: relative;
    }

    .talent {
        font-size: 0.8125rem;
        color: #fff;
        position: absolute;
        bottom: 0; left: 0;
        padding: 16px;
        font-weight: bold;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    }
    .sample {
        position: absolute;
        border-radius: 20px;
        text-align: center;
        background-color: #C2FFBF;
        color: #3A8B36;
        padding: 10px 15px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        right: -10px;
        top: -10px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    }
}

.button.playback {
    width: 2.8125rem;
    height: 2.8125rem;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border-radius: 1.40625rem;
    padding: 10px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    outline: none;
    
    svg {
        max-height: 18px;
    }
}

.pulse {
    animation: pulse 2s infinite;
    border-radius: 50%;
}

.pulse {
    &.red {
        animation: pulse-red 2s infinite;
    }

    &.green {
        animation: pulse-green 2s infinite;
    }
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-green {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
	}
}