// Sizing vars
$max-width: 1440px;
$navHeight: 55px;
$navHeightMd: 60px;

// Use mobile first styles apply breakpoints for changes as the size gets larger

$xxs-only: (
  max: 400px,
);

$xs: (
  max: 767px,
);
$sm: (
  min: 768px,
);

$tablet-portrait: (
  min: 710px,
  max: 950px,
);

$tablet-landscape: (
  min: 960px,
  max: 1020px,
);

$md: (
  min: 1024px,
);

$lg: (
  min: 1200px,
);

$xl: (
  min: 1500px,
);

$sm-only: (
  min: map-get($sm, min),
  max: map-get($md, min) - 1,
);

$md-only: (
  min: map-get($md, min),
  max: map-get($lg, min) - 1,
);

$sm-max: (
  max: map-get($md, min) - 1,
);

@mixin forIE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) {
    $query: append($query, "(min-width: #{map-get($map, min)})");
  }
  @if map-has-key($map, min) and map-has-key($map, max) {
    $query: append($query, "and");
  }
  @if map-has-key($map, max) {
    $query: append($query, "(max-width: #{map-get($map, max)})");
  }
  @media screen and #{$query} {
    @content;
  }
}
