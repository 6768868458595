@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Roboto:400,700&display=swap');

html, body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}

p {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 1.5625rem;
    color: #303030;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    margin-bottom: 1.25rem;
    color: #1C1C1C;
}

.tagline {
    font-family: "Josefin Sans", sans-serif;
    color: #fff;
}

h1 {
    line-height: 4rem;
}

h2 {
    font-size: 32px;
    line-height: 2.8125rem;
}

h3 {
    font-size: 30px;
    line-height: 2.8125rem;
}

ul, ol {
    margin-left: 15px;
    li {
        list-style: outside;
        margin-left: 15px;
    }
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.section {
    &.dark {
        h1, h2, h3, h4, h5, h6 {
            color: #DFDFDF;
        }

        p {
            color: #A3A3A3;
        }
    }
}