body {
    padding-top: 70px;
    background-color: #F5F5F5;
}

svg {
    width: 100%;
    height: auto;
    path {
        fill: currentColor;
    }
}

.hero {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    position: relative;
    width: 100%;
    overflow: hidden;

    @include breakpoint($sm) {
        // max-height: 700px;
        max-height: 50vh;
    }

    video {
        width: 100%;
        height: 100%;

        @include breakpoint($sm) {
            object-fit: cover; 
        }
    }

    .content {
        position: absolute;
        color: #fff;
        z-index: 10;
    }

    .tagline {
        width: 100%;
        text-align: center;
        text-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 0;
        
        @include breakpoint($xs) {
            font-size: 1.8125rem;
            line-height: 1.5;
            margin-top: 0;
        }

        @include breakpoint($sm) {
            font-size: 2.8125rem;
        }

        @include breakpoint($md) {
            font-size: 3.5rem;
            line-height: 1.25;
        }

        @include breakpoint($lg) {
            font-size: 4.5rem;
            line-height: 1.25;
        }
    }
}

.navbar {
    padding: 15px 0;
    position: fixed;
    background-color: transparent;
    top: 0; left: 0; right: 0;
    z-index: 11;
    transition: background-color, color  0.3s ease-in-out;
    // text-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);

    h3 {
        color: #000;
        font-size: 1.5rem;
        transition: font-size, color 0.3s ease-in-out;
    }

    svg {
        transition: max-width 0.3s ease-in-out;
        max-width: 195px;
        path {
            transition: color 0.3s ease-in-out;
            color: #000;
        }
    }

    &.scrolled {
        background-color: #fff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        text-shadow: unset;

        h3 {
            font-size: 1.2rem;
            color: #000;
        }

        svg {
            max-width: 120px;
            path {
                color: #000;
            }
        }
    }
}

.navbar-items {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    padding: 0 1.5625rem;

    @include breakpoint($sm) {
        @include justify-content(space-between);
    }
}

.navbar-item {
    @include justify-content(stretch);
    margin: 0 20px;
    font-weight: normal;
    display: none;

    & > * {
        margin: 0;
    }

    @include breakpoint($sm) {
        @include flexbox;
    }
}

.dynamic-word-enter {
    opacity: 0;
}

.dynamic-word-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.dynamic-word-exit { 
    opacity: 1;
}

.dynamic-word-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.section {
    &:first-of-type {
        padding-bottom: 0;
    }
    padding: 4.6875rem 0;
    @include breakpoint($sm) {
        
    }

    &.dark {
        color: #fff;
        background-color: #292929;
    }
}

figure {
    padding: 1.5625rem 0;
}

.field {
    margin-bottom: 1.5625rem;
}

.input {
    padding: 0.625rem 1.25rem;
    font-size: 1.25rem;
    border-radius: 30px;
    background-color: #EBEBEB;
    border: none;
    width: 100%;
}

.button {
    border-radius: 40rem;
    padding: 1.25rem 2.25rem;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: #2B2B2B;
    color: #EFEFEF;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    border: none;
    &:hover {
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.45);
    }
}

.panel {
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 3px 10px hsla(0, 0, 0, 0.15);

    .header, .footer {
        padding: 20px;
    }

    .body {
        padding: 0 20px;
    }

    .header {
        h4, h3 {
            margin: 0;
        }
    }

    .footer {
        background-color: #F4F4F4;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        p {
            margin: 0;
        }

        &.center {
            text-align: center;
        }
    }
}

.legal {
    padding: 15px 20px 0;
    color: #ccc;
    font-size: 0.6875rem;
    a {
        color: inherit;
    }
}

.table {
    width: 100%;
    border: 1px solid #ccc;
    td {
        padding: 16px;
        vertical-align: top;
    }
}

ul, ol {
    margin-bottom: 30px;
    li {
        margin-bottom: 10px;
    }
}

ul {
    &.important {
        font-size: 20px;

        li {
            margin-bottom: 2rem;
        }
    }
    &.check {
        margin-left: 0;
        li {
            list-style: none;
            position: relative;
            padding-left: 2rem;

            &:before {
                content: "";
                width: 20px;
                height: 20px;
                top: 0;
                left: 0;
                position: absolute;
                background: url("../images/bullet-check.svg");
                background-size: contain;
            }
        }
    }
}

.section-title {
    width: 100%;
    margin-bottom: 60px;
    h2 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 24px;
        // margin-bottom: 3px;
        line-height: 27px;
    }

    .title-border {
        width: 100%;
        height: 3px;
        position: relative;
        background-color: #E1E1E1;
    }

    .border-accent {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 300px;
        background-color: #676767;
    }
}